<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">考勤管理</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="content">
      <!--      v-if="list && list.length > 0"-->
      <van-list
        v-if="list && list.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="onLoad"
      >
        <div class="reverse">
          <div v-for="(item, index) in list" :key="index" class="dish-card">
            <div class="title">
              <!--              <div style="padding: 0 0 0.5rem 1rem;font-weight: 600;width: 30%">{{ item.date }}</div>-->
              <div class="day">{{ item.date }}</div>
              <div class="day">{{ item.title }}</div>
            </div>

            <div class="card">
              <div class="eat-time">
                <div>考勤类型</div>
                <van-tag type="primary" size="medium" v-if="item.mode ===1">双机考勤</van-tag>
                <van-tag type="primary" size="medium" v-else-if="item.mode ===2">签到</van-tag>
                <van-tag type="primary" size="medium" v-else-if="item.mode ===3">单机考勤</van-tag>
                <van-tag type="primary" size="medium" v-else>双机考勤</van-tag>
              </div>
              <div class="eat-time" style="padding-top: 0.15rem">
                <div>到达状态</div>
                <van-tag v-if="item.arrivalStatus === 0" type="primary" size="medium">正常</van-tag>
                <van-tag
                  v-if="item.arrivalStatus === 1"
                  :type="item.mode==2?'danger':'warning'"
                  size="medium"
                >{{item.mode ==2?'异常':'迟到'}}</van-tag>
                <van-tag v-if="item.arrivalStatus === 2" type="danger" size="medium">缺勤</van-tag>
                <van-tag v-if="item.arrivalStatus === 3" type="success" size="medium">请假</van-tag>
              </div>
              <div class="eat-time" v-show="item.mode!==2">
                <div>离开状态</div>
                <van-tag v-if="item.departureStatus === 0" type="primary" size="medium">正常</van-tag>
                <van-tag v-if="item.departureStatus === 1" type="warning" size="medium">早退</van-tag>
                <van-tag v-if="item.departureStatus === 2" type="danger" size="medium">缺勤</van-tag>
                <van-tag v-if="item.departureStatus === 3" type="success" size="medium">请假</van-tag>
              </div>
              <div class="eat-time">
                <div>到达时间</div>
                <div>{{ item.arrivalTime || '一'}}</div>
              </div>
              <div class="eat-time" style="border-bottom: none" v-show="item.mode!==2">
                <div>离开时间</div>
                <div>{{ item.departureTime ||"一" }}</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty v-else description="暂无数据" style="padding-top: 40%" />
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import { getList, getCheckAPI } from "@/api/checkWork";
import cancel from "@/assets/cancel.png";
export default {
  name: "CheckWork",
  data() {
    return {
      show: false,
      cancel,
      list: [],
      loading: false,
      finished: false,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  // computed: {
  //   date() {
  //     return (day) => {
  //       if (day) {
  //         return day.substr(-8);
  //       } else {
  //         return "-";
  //       }
  //     };
  //   },
  // },
  created() {
    const { pagination } = this;
    // const { current, size } = this.pagination
    this.fetch(pagination);
  },
  methods: {
    async fetch(params) {
      const { list } = this;
      list.length === 0 && (this.show = true);
      const { code, data, message } = await getList(params);
      if (code === 0) {
        this.list = [...list, ...data.records];
        this.pagination = {
          current: data.current,
          size: data.size,
          total: data.total,
        };
        console.log(this.list, 1111);

        setTimeout(() => {
          this.loading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },
    toHome() {
      this.$router.back();
    },
    onLoad() {
      const { current, size, total } = this.pagination;
      if (current * size >= total) {
        this.finished = true;
      } else {
        this.fetch({ current: current + 1, size });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    padding: 4rem 1rem 0 1rem;
    .reverse {
      .dish-card {
        padding-bottom: 1.5rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 0.625rem 1rem;
      box-shadow: 0 0 0.875rem #dcdcdc;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .eat-time {
        border-bottom: 1px dashed #ccc;
        padding: 0.5rem 0;
        //color: #07c160;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
