import request from '@/utils/request'

export function getList(params) {
  return request({
    url: `/icbc/attendance/pagination`,
    method: 'get',
    params
  })
}



// 考勤记录API    /user/register
export function getCheckAPI(params) {
  return request({
    url: `/user/register`,
    method: 'get',
    params
  })
}
